import Image from "next/image";
import Header from "@/components/Header";
import Hero from "@/components/Hero";
import Head from "next/head";
import dynamic from "next/dynamic";
import ContactInfo from "../components/Common/ContactInfo";
import Team from "@/components/Team";

const Footer = dynamic(() => import("../components/Footer"));
const Services = dynamic(() => import("../components/Services"));
const CaseStudies = dynamic(() => import("../components/CaseStudies"));
const Process = dynamic(() => import("../components/Process"));
const Clients = dynamic(() => import("../components/Clients"));
const Reviews = dynamic(() => import("../components/Reviews"));
const Technologies = dynamic(() => import("../components/Technologies"));

export default function Home() {
  return (
    <>
      <Head>
        <title>Software Development Agency</title>
        <meta
          name="description"
          content="Unlock the potential of web and app development with cutting-edge features, creating immersive and interactive experiences for your users."
        />
        <link rel="canonical" href="https://zysoftec.com/" />
      </Head>
      <main className=" overflow-x-hidden">
        <ContactInfo />
        <div className={`z-10 relative`}>
          <div className="w-full mx-auto fixed bg-[#ffffffa0] z-50 top-0">
            <Header />
          </div>
          <Hero />
          <Image
            src={"/images/hero-new-bg.svg"}
            alt="Home Page"
            width={789}
            height={651}
            className="absolute -top-52 xl:top-0 -right-60 -z-10 w-full h-full"
            priority
          />
        </div>
        <div>
          <Clients />
          <Services />
          <Team />
          {/* <About /> */}
          {/* <Solution /> */}
          <Technologies />
          <CaseStudies />
          <Process />
          {/* <Collaboration /> */}
          <Reviews />
          {/* <HomeContentCards /> */}
          {/* <BlogHome blogs={blogs} /> */}
          <Footer />
        </div>
      </main>
    </>
  );
}
