import Image from "next/image";
import Link from "next/link";
import Animate from "../Common/AnimateOnScroll";
import { motion } from "framer-motion";
import Typewriter from "typewriter-effect";

const Hero = () => {
  return (
    <div className=" max-w-screen-xl mx-auto flex flex-col-reverse xl:flex-row xl:items-center relative px-8 xl:px-0 h-[28vh] md:h-[70vh]  mb-5 md:mb-0  mt-[15rem] md:mt-20 xl:mt-10 xl:gap-38">
      <Animate.ScaleIn>
        <div className="flex flex-col space-y-3 items-center w-full lg:w-[700px]">
          <div className="relative flex items-center w-full">
          <motion.h1
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 0.1, duration: 0.2, stiffness: 200 }}
        className="w-full  text-primary text-3xl md:text-5xl 2xl:text-6xl font-extrabold"
      >
        <span className="text-secondary text-3xl md:text-5xl 2xl:text-6xl font-extrabold ">
          Software Solutions </span>{" "}
        <Typewriter
      
          options={{
            strings: ["You Can Trust"],
            autoStart: true,
            loop: true,
          }}
        />
      </motion.h1>
            {/* <p className="text-primary text-3xl md:text-5xl 2xl:text-6xl font-extrabold  w-full">
              Software&nbsp;
              <span className="text-secondary text-3xl md:text-5xl 2xl:text-6xl font-extrabold ">
                Solutions
              </span>
              <br /> You Can Trust
            </p> */}
            <Image
              src={"/images/hero_circle.svg"}
              width={70}
              height={70}
              className="absolute -left-7 -top-2 -z-10"
              alt="hero"
            />
          </div>
          <div className="flex items-center justify-start w-full">
            <div className="text-base fontNew max-w-lg">
           {` ZySoftec transforms your digital vision into reality. As a leading software company, we specialize in a comprehensive range of software solutions designed to elevate your online presence. Whether you're a startup looking to establish your brand or an established business aiming for digital innovation, Zysoftec is your trusted partner in achieving success.`}
         
            </div>
          </div>
          <div className="flex items-center justify-start w-full space-x-5">
            <Link
              href={"/case-study"}
              style={{
                background:
                  "linear-gradient(280.91deg, #1A3183 28.64%, #16349F 77.71%)",
              }}
              className="px-2 md:px-8 py-3 flex items-center justify-center rounded-[5px] scale-100 hover:scale-105 hover:ease-in hover:duration-200"
            >
              <span className="text-xs md:text-base fontNew text-white font-normal ">
                Our Recent Work
              </span>
            </Link>
            <Link
              href={"/book"}
              className="px-2 md:px-8 py-3 flex items-center justify-center rounded-[5px] border border-[#1A3183] scale-100 hover:scale-105 hover:ease-in hover:duration-200"
            >
              <span className="text-xs md:text-base text-primary font-normal fontNew">
                Book an Appointment
              </span>
            </Link>
          </div>
        </div>
      </Animate.ScaleIn>

      <div className="relative invisible md:visible z-20 ">
        <Animate.ScaleIn>
          <Image
          // className="w-[300px] "
            src={"/images/hero_img.svg"}
            width={600}
            height={600}
            priority={true}
            alt="zysoftec"
          />
        </Animate.ScaleIn>
        <motion.div
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 0.4, duration: 0.1, stiffness: 200 }}
     
      >
        <Image
          src={"/images/hero_circle1.svg"}
          width={135}
          height={135}
          className="absolute -left-24 top-[32%]"
          alt="heroimg"
        />
       </motion.div>
      </div>
    </div>
  );
};

export default Hero;
