import Image from "next/image";
import Link from "next/link";
import { Kumbh_Sans } from "next/font/google";
import Animate from "../Common/AnimateOnScroll";


const kumbhsans = Kumbh_Sans({
  subsets: ["latin"],
  weight: ["700"],
});

const ServiceCard = ({ img, title, desc, btnBg, link, alt }) => {
  return (
    <Animate.ScaleIn>
    <div className=" flex flex-col space-y-2 items-center justify-start py-10 relative  h-[350px] px-2 hover:scale-[1.1] transition-all duration-500 my-10 sm:my-0">
      <div className="relative">
        <Image
          src={img}
          width={200}
          height={200}
          alt="vector"
          className="max-w-[300px] max-h-[400px]"
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
        {/* <Image
          src={img}
          width={500}
          height={500}
          alt="services"
          className="max-w-[35px] max-h-[40px]  service-card-img"
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        /> */}
      </div>
      <p
        className={`text-primary flex justify-start items-center text-center font-semibold text-base md:text-xl `}
      >
        {title} 
      </p>
      <span className="text-sm flex flex-col justify-center items-center font-normal text-center px-2 fontNew">
        {desc} 
      </span>
    
    </div>
    </Animate.ScaleIn>
  );
};

export default ServiceCard;
