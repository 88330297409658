import Image from "next/image";
import ServiceCard from "./ServiceCard";
import Animate from "../Common/AnimateOnScroll";
import Link from "next/link";
import SliderButtons from "../Common/SliderButtons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper";

const Team = () => {
  const services = [
    {
      title: "Founder & CEO",
      desc: "Zill E Huma",
      img: "/images/ceo_zil.png",
      // link: "/services/landing-page-design-agency",
      // alt: "Landing Page Development",
    },
    {
      title: "Co-Founder",
      desc: "Osama Bin Saleem",
      img: "/images/co_founder.png",
      // link: "/services/custom-web-development-services",
      // alt: "Website Development",
    },
    // {
    //   title: "Admin",
    //   desc: "Sayeda Afifa",
    //   img: "/images/afifa.png",
    //   // link: "/services/admin-panel-development-services",
    //   // alt: "Web Admin Panels",
    // },
    {
      title: "MERN Developer",
      desc: "Awais Nazar",
      img: "/images/awais.png",
      // link: "/services/API-integration-services",
      // alt: "API Integrations",
    },
    {
      title: "SEO Specialist",
      desc: "Usman",
      img: "/images/usman.png",
      // link: "/services/API-integration-services",
      // alt: "API Integrations",
    },
    {
      title: "Frontend Developer",
      desc: "Taha",
      img: "/images/taha.png",
      // link: "/services/Hire-android-developers",
      // alt: "Android App Development",
    },
    {
      title: "UIUX Designer",
      desc: "Mukhtar Hussain ",
      img: "/images/mukhtar.png",
      // link: "/services/Hire-android-developers",
      // alt: "Android App Development",
    },
   
  
  ];
  return (
    <div className="relative flex items-center justify-center flex-col space-y-4  px-8 xl:px-5 py-10 ">
      <Animate.ScaleIn>
        <div className="flex flex-col items-center space-x-2 space-y-2 text-center ">
          <div className="flex items-center space-x-2 text-center fontNew">
            <p className={`text-2xl xl:text-5xl font-bold text-secondary `}>
              Our 
            </p>
            <p className={`text-2xl xl:text-5xl font-bold text-primary `}>
              Team
            </p>
          </div>
          <span className="text-base font-normal max-w-[100%] text-center fontNew">
            ZySoftec has an in-house dedicated team.
          </span>
        </div>
      </Animate.ScaleIn>
    
      <div className="max-w-[300px] md:max-w-screen-xl mx-auto">
        <div className="flex flex-col justify-center items-center">
          {/* <div className=" grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 gap-10 md:gap-10"> */}
           
            <Swiper
             pagination={{
              dynamicBullets: true,
            }}
            freeMode={false}
            grabCursor={true}
            modules={[Pagination, Autoplay]}
            autoplay
            className="mySwiper"
            breakpoints={{
              768: {
                slidesPerView: 5,
                spaceBetween: 20,
              },
              0: {
                slidesPerView: 1,
                spaceBetween: 50,
              },
            }}
            style={{
              width: "100%",
              paddingTop: "4rem",
              paddingBottom: "4rem",
            }}
            id="case-study-slider"
            >
              <SliderButtons />
              {/* <div className="grid grid-cols-3 gap-3"> */}
                {services?.map((service, i) => (
                  <SwiperSlide key={service.title}>
                    <ServiceCard
                      title={service?.title}
                      alt={service?.alt}
                      desc={service?.desc}
                      img={service?.img}
                      btnBg={i % 2 === 0 ? "#FA7070" : "#16349F"}
                      link={service?.link}
                    />
                  </SwiperSlide>
                ))}
              {/* </div> */}
            </Swiper>
          {/* </div> */}
    
        </div>
      </div>

      <Image
        src={"/images/tech-circle.svg"}
        width={100}
        height={100}
        className="absolute left-0 -top-10 -z-10 md:w-[300px] md:h-[300px] xl:w-[450px] xl:h-[450px]"
        alt="services"
      />
    
    </div>
  );
};

export default Team;
