import Image from "next/image";
import Link from "next/link";
import { IoLogoWhatsapp } from "react-icons/io";

const ContactInfo = () => {
  return (
    <>
      <Link
        href={"https://www.fiverr.com/zill_e_huma"}
        target="_blank"
        className="pulsefi py-3  fixed right-8 z-50  flex items-center rounded-full w-[70px] space-x-2 h-[70px] justify-center  "
        style={{ bottom: "180px" }}
      >
        <Image
          src={"/images/fi_logo.webp"}
          width={45}
          height={45}
          alt="book a meet now with zysoftec"
          className=" py-3 position_fi z-50  flex items-center rounded-full w-[44px] space-x-2 h-[67px] justify-center "
        />
      </Link>
      <Link
        href={"https://wa.me/3175682252"}
        target="_blank"
        className="pulsewp py-3 fixed bottom-[110px] z-50 right-8 flex items-center rounded-full w-[70px] space-x-2 h-[70px] justify-center  "
      >
        <IoLogoWhatsapp
          color="#58F26F"
          className="py-3 fixed bottom-[110px] z-50 right-8 flex items-center rounded-full w-[70px] space-x-2 h-[70px] justify-center "
        />
      </Link>
      <Link
        href={"/book"}
        style={{
          background:
            "linear-gradient(280.91deg, #1A3183 28.64%, #16349F 77.71%)",
        }}
        className="py-3 fixed bottom-8 z-50 right-8 flex items-center rounded-full w-40 space-x-2 h-14 justify-center pulse"
      >
        <Image
          src={"/images/today-32.webp"}
          width={23}
          height={23}
          alt="book a meet now with zysoftec"
        />
        <span className={`text-base text-white font-normal `}>Book A Meet</span>
      </Link>
    </>
  );
};

export default ContactInfo;
