import Image from "next/image";
import { useSwiper } from "swiper/react";

const SliderButtons = () => {
  const swiper = useSwiper();

  return (
    <div className="flex space-x-4 items-center absolute top-5 md:top-0 right-0">
      <button
        onClick={() => swiper.slidePrev()}
        className="px-4 py-2 text-white rounded-md"
        style={{
          background:
            "linear-gradient(280.91deg, #1A3183 28.64%, #16349F 77.71%)",
        }}
        aria-label="prev"
      >
        <Image src={"/images/arrow2.svg"} width={9} height={18} alt="arrow" />
      </button>
      <button
        onClick={() => swiper.slideNext()}
        className="px-4 py-2 text-white rounded-md"
        style={{
          background: swiper.isEnd
            ? "#ABACAC"
            : "linear-gradient(280.91deg, #1A3183 28.64%, #16349F 77.71%)",
        }}
        aria-label="next"
      >
        <Image src={"/images/arrow1.svg"} width={9} height={18} alt="arrow" />
      </button>
    </div>
  );
};

export default SliderButtons;
